import 'alpinejs'
// import CookieConsent from "./components/cookie-consent";

// document.addEventListener('DOMContentLoaded', function () {
//     new CookieConsent().init();
// });


//Navigations Dots
let mainNavLinks = document.querySelectorAll(".dot-navigation a");
let offset = -200;

updateDotNavigation();
window.addEventListener("scroll", updateDotNavigation);

function updateDotNavigation() {
    let windscroll = window.scrollY - offset;
    if (windscroll >= 100) {
        mainNavLinks.forEach(function(link) {
            let section = document.querySelector(link.hash);

            if (section) {
                if (section.offsetTop <= windscroll - 20) {
                    mainNavLinks.forEach(elem => {
                        elem.parentNode.classList.remove("current");
                    });

                    link.parentNode.classList.add("current");
                }
            }
        });
    } else {
        mainNavLinks.forEach(elem => {
            elem.parentNode.classList.remove("current");
        });
    }
}


//SmoothScroll
import SmoothScroll from "smooth-scroll";
var scroll = new SmoothScroll('a[data-scroll]', {
  speed: 300,
  offset: 450
});
